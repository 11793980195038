.wrapper {
    height: 10vh;
    /*This part is important for centering*/
    display: flex;
    place-items: start;
  }
  
  .typing-learning {
    width: 25ch;
    animation: typing 2s steps(25), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid darkgray;
    font-family: monospace;
    font-size: 2em;
  }

  .typing-consulting {
    width: auto;
    animation: typing 2s steps(auto), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid darkgray;
    font-family: monospace;
    font-size: 2em;
  }

  .typing-technology {
    width: 28ch;
    animation: typing 2s steps(28), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid darkgray;
    font-family: monospace;
    font-size: 2em;
  }

  .typing-about {
    width: 23ch;
    animation: typing 2s steps(23), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid darkgrey;
    font-family: monospace;
    font-size: 2em;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }
      
  @keyframes blink {
    50% {
      border-color: transparent
    }
  }
