
.parallax-card {
    --f: .01; /* the parallax factor (the smaller the better) */
    --r: 100px; /* radius */
    
    --_f: calc(100%*var(--f)/(1 + var(--f)));
    --_a: calc(90deg*var(--f));
    width: 500px; /* the image size */
    aspect-ratio: calc(1 + var(--f));
    object-fit: cover;
    clip-path: inset(0 var(--_f) 0 0 round var(--r));
    transform: perspective(400px) var(--_t,rotateY(var(--_a)));
    transition: .5s;

  }
  .parallax-card:hover {
    clip-path: inset(0 0 0 var(--_f) round var(--r));
    --_t:translateX(calc(-1*var(--_f))) rotateY(calc(-1*var(--_a)))
  }
  
